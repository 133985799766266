<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-data-table
      :headers="headers"
      :height="tableSize"
      :items-per-page="pageSize"
      :items="batches"
      :search="search"
      :loading="loading"
      @click:row="viewReport"
      dense
      fixed-header
      hide-default-footer
      item-key="id"
      style="cursor: pointer">
      <template #top>
          <v-toolbar flat color="toolbar">
            <v-col md="3" lg="3" xl="2" class="pl-0 pr-2">
              <v-autocomplete v-if="!isTprProRetailUser"
                :items="userAdGroups"
                background-color="input"
                class="mt-7 ma-0"
                dense
                item-text="name"
                item-id="id"
                label="Ad Group"
                :menu-props="{ maxHeight: 210 }"
                outlined
                return-object
                @change="getBatches"
                v-model="adGroup">
                <template #append>
                  <v-progress-circular
                    v-if="userGroupsLoading"
                    indeterminate
                    size="25"
                    color="primary" />
                </template>
              </v-autocomplete>
              <v-autocomplete v-else
                :items="userStores"
                background-color="input"
                class="mt-7 ma-0"
                dense
                item-text="store_name"
                item-id="store_party_id"
                label="Store"
                :menu-props="{ maxHeight: 210 }"
                outlined
                return-object
                @change="getStoreBatches"
                v-model="store">
              </v-autocomplete>
            </v-col>
            <v-col mx="0" md="2" lg="2" xl="4" class="pl-0 pr-2" style="max-width: 255px">
              <v-menu
                :close-on-content-click="false"
                :nudge-left="5"
                bottom
                min-width="auto"
                offset-y
                ref="menu"
                transition="scale-y-transition"
                v-model="menu">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :label="batchDateLabel"
                    :value="dateText"
                    @click:clear="clearBatchDate"
                    clearable
                    background-color="input"
                    dense
                    readonly
                    hide-details=""
                    outlined
                    prepend-inner-icon="mdi-calendar-range"
                    v-bind="attrs"
                    v-on="on" />
                </template>
                <v-date-picker v-if="!isTprProRetailUser"
                  :show-current="false"
                  :max="batchEndDate !== batchDate ? batchEndDate : null"
                  @change="getBatches"
                  no-title
                  v-model="batchDate">
                  <v-spacer />
                  <v-btn :class="body" text @click="batchDate = ''" @blur="getBatches">
                    Clear
                  </v-btn>
                </v-date-picker>
                <v-date-picker v-else
                  :show-current="false"
                  :max="batchEndDate !== batchDate ? batchEndDate : null"
                  @change="getStoreBatches"
                  no-title
                  v-model="batchDate">
                  <v-spacer />
                  <v-btn :class="body" text @click="batchDate = ''" @blur="getStoreBatches">
                    Clear
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col ml="5" md="2" lg="2" xl="4" class="pl-0 pr-2" style="max-width: 255px"  v-if="tprDateAsRange">
              <v-menu
                :close-on-content-click="false"
                :nudge-left="5"
                bottom
                min-width="auto"
                offset-y
                ref="endMenu"
                transition="scale-y-transition"
                v-model="endMenu">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Batch End Date"
                    :value=endDateText
                    @click:clear="clearEndDate"
                    clearable
                    readonly
                    background-color="input"
                    dense
                    hide-details=""
                    outlined
                    prepend-inner-icon="mdi-calendar-range"
                    v-bind="attrs"
                    v-on="on" />
                </template>
                <v-date-picker
                  :min="batchDate"
                  :show-current="false"
                  @change="!isTprProRetailUser ? getBatches : getStoreBatches"
                  no-title
                  v-model="batchEndDate">
                  <v-spacer />
                  <v-btn :class="body" text @click="clearEndText()">
                    Clear
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-spacer />
            <v-menu
              v-if="isFiltered"
              bottom
              offset-y
              open-on-hover
              max-height="300">
              <template v-slot:activator="{ on }">
                <v-chip v-on="on" class="orange white--text ml-2">
                  <span class="text-capitalize">
                    <v-icon color="white" class="mr-2" small>
                      mdi-filter-outline
                    </v-icon>
                    Results for {{ searchValues.length }} search values
                  </span>
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-btn
                        v-on="on"
                        @click="searchValues = []"
                        class="white--text ml-2"
                        icon
                        x-small>
                        <v-icon small>mdi-close-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Clear Filter</span>
                  </v-tooltip>
                </v-chip>
              </template>
              <v-card>
                <v-card-title class="text-subtitle-2 pb-0">
                  Search Values:
                </v-card-title>
                <v-list>
                  <v-list-item
                    v-for="(value, index) in searchValues"
                    :key="index">
                    {{ value }}
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
            <v-spacer />
            <ItemSearch v-if="!isTprProRetailUser" @search="searchValues = $event" @clear="getBatches()" />
            <ItemSearch v-else @search="searchValues = $event" @clear="getStoreBatches()" />
            <v-menu 
              :close-on-content-click="false"
              :nudge-bottom="3"
              :nudge-left="40"
              :value="createMenu"
              bottom
              offset-y>
              <template #activator="{ on: menu, attrs }">
                <v-tooltip top>
                  <template #activator="{ on: tooltip }">
                    <v-btn v-if="!isTprProRetailUser"
                      small
                      :class="body"
                      @click="createMenu = true"
                      class="grey lighten-2 ml-2"
                      height="38"
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }">
                      <v-icon>mdi-folder-plus-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Create Batch</span>
                </v-tooltip>
              </template>
              <v-card class="fill">
                <v-card-title class="pb-0 text-subtitle-1">
                  Create TPR Batch
                </v-card-title>
                <v-form ref="form" class="fill">
                  <v-text-field
                    :rules="[v => !!v || 'Name Required']"
                    background-color="input"
                    class="pt-4 px-4"
                    clearable
                    dense
                    label="Batch Name"
                    outlined
                    v-model="batchName">
                  </v-text-field>
                  <v-autocomplete
                    v-if="showTprSrpUpload"
                    :rules="[v => !!v || 'Batch Type Required']"
                    :items="batchTypes"
                    class="px-4"
                    background-color="input"
                    outlined
                    dense
                    clearable
                    label="Batch Type"
                    v-model="batchType">
                  </v-autocomplete>
                  <v-container>
                    <v-row>
                      <v-col class="ma-0 pa-0">
                        <v-text-field
                        :rules="[v => !!v || 'Date Required']"
                        :value="dateText"
                        background-color="input"
                        class="px-4 pb-0"
                        clearable
                        readonly
                        dense
                        :label="batchDateLabel"
                        outlined />
                      </v-col>
                      <v-col class="ma-0 pa-0" v-if="tprDateAsRange">
                        <v-text-field
                          :rules="[v => !!v || 'Date Required']"
                          :value="endDateText"
                          background-color="input"
                          class="px-4 pb-0"
                          clearable
                          readonly
                          dense
                          label="Batch End Date"
                          outlined />
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-divider />
                  <v-date-picker
                    :allowed-dates="allowedDates"
                    :max="batchEndDate || null"
                    :show-current="false"
                    color="primary"
                    no-title
                    v-model="batchDate"
                    width="326">
                  </v-date-picker>
                  <v-date-picker
                    v-if="tprDateAsRange"
                    :min="batchDate"
                    :allowed-dates="allowedEndDates"
                    :show-current="false"
                    color="primary"
                    no-title
                    v-model="batchEndDate"
                    width="326">
                  </v-date-picker>
                </v-form>
                <v-divider />
                <v-card-actions flat class="fill">
                  <v-spacer />
                  <v-btn :class="body" text @click="createMenu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    :class="body"
                    class="green white--text"
                    @click="batchCreate">
                    Create
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
            <TprSrpUpload v-if="showTprSrpUpload" :value="tprSrpUploadMenu" :baseAdGroup="adGroup" @close="closeTprSrpUploadMenu"/>
            <v-col md="3" lg="3" xl="4" class="pl-2 pr-0">
              <v-text-field
                background-color="input"
                class="mt-7"
                dense
                label="Filter Batches"
                outlined
                prepend-inner-icon="mdi-filter-outline"
                v-model="search" />
            </v-col>
          </v-toolbar>
        <v-divider />
      </template>

      <template #[`item.batch_type`]="{ item }">
        <v-chip 
        v-if="item.batch_type" class="mr-4" small dark :color="getColor(item.batch_type)">
          <span
            style="
              display: inline-block;
              margin: 0 auto;
              width: 120px;
              text-align: center;
            ">
            <span>{{ item.batch_type }}</span>
          </span>
        </v-chip>
      </template>

      <template #[`item.tpr_date`]="{ item }">
        <td class="noWrap">{{ formatDateDisplay(item.tpr_date) }}</td>
      </template>

      <template #[`item.tpr_end_date`]="{ item }">
        <td class="noWrap">{{ formatDateDisplay(item.tpr_end_date) }}</td>
      </template>

      <template #[`item.created_on`]="{ item }">
        <td class="noWrap">{{ formatDateDisplay(item.created_on) }}</td>
      </template>

      <template #[`item.updated_by`]="{ item }">
        <td class="noWrap">
          {{ item.updated_by !== 'None' ? item.updated_by : '---' }}
        </td>
      </template>

      <template #[`item.updated_on`]="{ item }">
        <td class="noWrap">
          {{ item.updated_on ? formatDateDisplay(item.updated_on) : '---' }}
        </td>
      </template>

      <template #[`item.status`]="{ item }">
        <v-chip class="mr-4" small dark :color="getColor(item.status)">
          <span
            style="
              display: inline-block;
              margin: 0 auto;
              width: 90px;
              text-align: center;
            ">
            <span>{{ item.status }}</span>
          </span>
        </v-chip>
      </template>

      <template #[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template #activator="{ on }">
            <v-btn :disabled="!isBatchAvailable.includes(item.status) || isSyncing" v-on="on" icon class="mr-4" @click="selectedBatch = item">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(action, index) in actions"
              :key="index"
              link
              @click="action.method(item)">
              <v-list-item-icon class="mr-4">
                <v-icon>{{ action.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle>{{ action.text }}</v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template #[`item.batch_info`]="{ item }">
        <v-menu
          :close-on-content-click="false"
          :nudge-left="50"
          offset-x
          offset-y
          open-on-hover>
          <template #activator="{ on }">
            <v-btn
              color="primary"
              icon
              tabindex="-1"
              v-on="on"
              @click.stop="copyId(item)">
              <v-icon>mdi-information-variant</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Batch ID</v-list-item-subtitle>
                  <v-list-item-title v-if="!isTprProRetailUser">{{ item.id }}</v-list-item-title>
                  <v-list-item-title v-else>{{ item.tpr_batch_id }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </template>

      <template v-slot:footer>
        <v-divider></v-divider>
        <v-toolbar flat color="#fff" style="margin-top: 8px">
          <v-row align="center" style="padding-bottom: 3px">
            <v-spacer />
            <v-col cols="2" style="max-width: 150px">
              <v-select
                :items="pageSizes"
                :menu-props="{
                  top: true,
                  offsetY: true,
                  maxHeight: 500
                }"
                class="mt-7"
                dense
                label="Items Per Page"
                v-model="pageSize">
              </v-select>
            </v-col>
            <v-btn
              :disabled="disablePrevious"
              @click="previous"
              class="mx-2"
              icon
              large>
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <small>Page {{ page }}</small>
            <v-btn
              :disabled="disableNext"
              @click="next"
              class="mx-2"
              icon
              large>
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </v-toolbar>
      </template>
    </v-data-table>
    <AlertDialog
      :value.sync="alertDialog"
      :batch="selectedBatch"
      :total="total"
      @update="batchUpdate($event)" />
    <EditNameDialog
      :value.sync="editNameDialog"
      :batch="selectedBatch"
      @close="closeEditNameModal()" 
      @update="getBatches()" />
    <DeleteBatchDialog
      :value.sync="deleteBatchDialog"
      :batch="selectedBatch"
      @close="closeDeleteBatchModal()" 
      @update="getBatches()" />
    <v-overlay absolute :value="creating || exporting">
      <v-progress-circular indeterminate size="100">
        {{ creating ? 'Creating...' : exporting ? 'Exporting...' : '' }}
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import Batches from '@/axios/batches'
import ItemSearch from '@/components/ItemSearch'
import TprSrpUpload from '@/components/TprSrpUpload'
import { fullwidth } from '@/mixins/fullwidth'
import { paging } from '@/mixins/paging'
import { displayHelpers } from '@/mixins/displayHelpers'
import { userAccess } from '@/mixins/user-access'
import { utils } from '@/mixins/utils'
import { shared } from '@/mixins/shared'
import Rules from '@/axios/rules-management.js'

export default {
  name: 'BatchList',
  components: { ItemSearch, TprSrpUpload },
  mixins: [fullwidth, displayHelpers, paging, userAccess, utils, shared],
  data() {
    return {
      adGroup: {},
      batchDate: null,
      batchEndDate: null,
      batches: [],
      batchName: null,
      batchType: null,
      createMenu: false,
      creating: false,
      endMenu:false,
      interval: '',
      loading: false,
      menu: false,
      tprSrpUploadMenu: false,
      search: '',
      searchValues: [],
      selected: [],
      selectedBatch: {},
      userStores: [],
      store: {},
      userGroupsLoading: true,
      batchTypes: ["STANDARD", "PACKAGED MEAT", "VMC"]
    }
  },
  computed: {
    isTprProRetailUser() {
      return this.userGroups.includes('tpr-manager.pro')
    },
    tprDateAsRange() {
      return ['pricechopper'].includes(this.$auth.tenant)
    },
    showTprSrpUpload() {
      if(!this.isTprProRetailUser){
        return ['alliance-retail-group'].includes(this.$auth.tenant)
      }
      return false
    },
    shouldPoll() {
      return this.batches.some(
        batch => batch.status === 'PENDING' || batch.status === 'RUNNING'
      )
    },
    isFiltered() {
      return this.searchValues.length > 0
    },
    dateText() {
      return this.batchDate
        ? this.moment(this.batchDate).format(this.$dateConfig.date_display)
        : ''
    },
    endDateText() {      
      return this.batchEndDate
        ? this.moment(this.batchEndDate).format(this.$dateConfig.date_display)
        : ''
    },
    batchDateLabel() {
      if (this.tprDateAsRange) return "Batch Start Date"
      return "Batch Date"
    },
    headers() {
      const headers = [
        { sortable: false, class: 'accent white--text', width: '2%' },
        {
          text: 'Batch Name',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'batch_name'
        },
        {
          text: 'Batch Type',
          align: 'center',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'batch_type'
        },
        {
          text: this.tprDateAsRange ? 'TPR Start Date' : 'TPR Date',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'tpr_date'
        },
        {
          text: 'TPR End Date',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'tpr_end_date'
        },
        {
          text: 'Created By',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'created_by'
        },
        {
          text: 'Created On',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'created_on'
        },
        {
          text: 'Updated By',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'updated_by'
        },
        {
          text: 'Updated On',
          align: 'start',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'updated_on'
        },
        {
          text: 'Status',
          align: 'center',
          sortable: true,
          filterable: true,
          class: 'accent white--text',
          value: 'status'
        },
        {
          text: 'Actions',
          align: 'center',
          sortable: false,
          filterable: false,
          class: 'accent white--text',
          value: 'actions'
        },
        {
          align: 'center',
          sortable: false,
          filterable: false,
          class: 'accent white--text',
          value: 'batch_info'
        }
      ]
      if (!this.tprDateAsRange) headers.splice(4,1)
      if (this.$auth.tenant != 'alliance-retail-group') headers.splice(2,1)
      return headers
    },
    disablePrevious() {
      return (this.page - 1) * this.pageSize === 0
    },
    disableNext() {
      return this.batches.length < this.pageSize
    }
  },
  watch: {
    userAdGroups: {
      handler(newValue) {
        if (newValue?.length > 0 && !sessionStorage.tpr_manager_ad_group) {
          let index = 0
          if (this.$auth.tenant == 'pricechopper') {
            index = newValue.findIndex(newValue => newValue.name === 'PCE Ad Group')
          }
          this.adGroup = newValue[index]
        }
      },
      deep: true
    },
    userStores: {
      handler(newValue) {
        if( newValue?.length > 0 && !sessionStorage.tpr_pro_store){
          let index = 0
          this.store = newValue[index]
        }
      },
      deep: true
    },
    tprDate: {
      handler(newValue) {
        if (newValue) {
          sessionStorage.setItem(
            'tpr_manager_tpr_date',
            JSON.stringify(newValue)
          )
        }
      }
    },
    adGroup: {
      handler(newValue) {
        if (newValue && !this.isTprProRetailUser) {
          sessionStorage.setItem(
            'tpr_manager_ad_group',
            JSON.stringify(newValue)
          )
          this.userGroupsLoading = false
          this.getBatches()
        }
      },
      deep: true
    },
    store: {
      handler(newValue) {
        if(newValue) {
          sessionStorage.setItem(
            'tpr_pro_store', JSON.stringify(newValue)
          )
          this.getStoreBatches()
        }
      },
      deep: true
    },
    batchDate: {
      handler(newValue) {
        if (newValue) {
          this.menu = false
          sessionStorage.setItem('tpr_manager_tpr_date', newValue)
        } else {
          sessionStorage.removeItem('tpr_manager_tpr_date')
        }
        this.page = 1
      }
    },
    batchEndDate: {
      handler(newValue) {
        if (newValue) {
          this.endMenu = false
          sessionStorage.setItem('tpr_manager_tpr_end_date', newValue)
        } else {
          sessionStorage.removeItem('tpr_manager_tpr_end_date')
        }
        this.page = 1
      }
    },
    searchValues: {
      handler(newValue) {
        if (newValue) {
          sessionStorage.setItem(
            'tpr_manager_batch_item_search',
            JSON.stringify(newValue)
          )
          if(!this.isTprProRetailUser) {
            this.getBatches()
          } else {
            this.getStoreBatches()
          }
        }
      }
    },
    shouldPoll: {
      handler(newValue) {
        let interval
        if (newValue === true && !this.isTprProRetailUser) {
          interval = setInterval(() => {
            this.getBatches()
          }, 30000)
        } 
        if (newValue === true && this.isTprProRetailUser) {
          interval = setInterval(() => {
            this.getStoreBatches()
          }, 30000)
        } else {
          clearInterval(interval)
        }
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  created() {
    if(this.isTprProRetailUser){
      if(sessionStorage.tpr_pro_store)
      this.store = JSON.parse(sessionStorage.tpr_pro_store)
      if (sessionStorage.tpr_manager_tpr_date) {
        this.batchDate = sessionStorage.tpr_manager_tpr_date
      } else {
        this.batchDate = ''
      }
      if (this.tprDateAsRange) { 
        if (sessionStorage.tpr_manager_tpr_end_date) {
          this.batchEndDate = sessionStorage.tpr_manager_tpr_end_date
        }
      }
      if (sessionStorage.tpr_manager_batch_item_search) {
        this.searchValues = JSON.parse(
          sessionStorage.tpr_manager_batch_item_search
        )
      }
      this.tableChanged = this.tableChange(this.getStoreBatches)
      this.getStores()
      this.getStoreBatches()
    } else {
        if (sessionStorage.tpr_manager_ad_group)
        this.adGroup = JSON.parse(sessionStorage.tpr_manager_ad_group)
        if (sessionStorage.tpr_manager_tpr_date) {
        this.batchDate = sessionStorage.tpr_manager_tpr_date
      } else {
        this.batchDate = ''
      }
      if (this.tprDateAsRange) {
        if (sessionStorage.tpr_manager_tpr_end_date) {
          this.batchEndDate = sessionStorage.tpr_manager_tpr_end_date
        }
      }
      if (sessionStorage.tpr_manager_batch_item_search) {
        this.searchValues = JSON.parse(
          sessionStorage.tpr_manager_batch_item_search
        )
      }
      this.tableChanged = this.tableChange(this.getBatches)
    }
    sessionStorage.removeItem('tpr_manager_selected_batch')
    sessionStorage.removeItem('tpr_manager_tpr_date')
    sessionStorage.removeItem('tpr_manager_tpr_end_date')
    sessionStorage.removeItem('tpr_manager_item_search')
    this.nonTableHeight = 240
  },
  methods: {
    copyId(batch) {
      navigator.clipboard.writeText(batch.id)
      this.notify('Copied')
    },
    allowedDates(value) {
      if (this.tprDateAsRange) {
        return value
      }
      if (this.$auth.tenant === 'alliance-retail-group') {
        // Don't allow any dates until batch type is selected
        if (this.batchType == null)
          return ''

        // Filter batches down to ones with currently selected batch type, and then check if any dates conflict
        if (this.batches.filter(batch => batch.batch_type === this.batchType).map(batch => batch.tpr_date).includes(value)) {
          return ''
        }
      }
      else if (this.batches.map(batch => batch.tpr_date).includes(value)) {
        return ''
      }
      const availableDate = new Date(value)
      if (this.$auth.tenant === 'awg') {
        return availableDate.getDay() === 0 ? value : ''  // Monday
      }
      return availableDate.getDay() === 6 ? value : ''  // Sunday
    },

    allowedEndDates(value) {
      if (!this.batches.map(batch => batch.tpr_date).includes(this.batchDate)) {
        return value
      } else if (!this.batches.map(batch => batch.tpr_end_date).includes(value)) {
        return value
      }
      return ''
    },
    getColor(status) {
      switch (status) {
        case 'PENDING':
          return 'grey darken-1'
        case 'RUNNING':
          return 'amber darken-1'
        case 'COMPLETE':
          return 'green'
        case 'FAILED':
          return 'red'
        case 'PUBLISHED':
          return 'primary'
        case 'STANDARD':
          return 'primary'
        case 'VMC':
          return 'green'
        case 'PACKAGED MEAT':
          return 'amber darken-3'
        default:
          return
      }
    },
    async getStores(){
      try{
        const res = await Rules.getStores(this.$auth.user.email, this.$auth.tenant)
        this.userStores = res.data
      } catch (err) {
        console.log('Get stores Error: ', err)
      }
    },
    async getStoreBatches(){
      if(this.store.store_party_id) {
          if (!this.shouldPoll) this.loading = true
        this.startDt = null
        this.endDt = null
        if (this.batchDate) {
          this.startDt = this.batchDate
        }
        if (this.batchEndDate) {
          this.endDt = this.batchEndDate
        }
        try {
          const { data }  = await Rules.getStoreBatches(
            this.store.store_party_id,
            this.startDt,
            this.endDt,
            this.pageSize,
            this.from,
            this.searchValues
          )
          this.batches = data
        } catch (err) {
          console.error(err)
          const message = err?.response?.data?.message || err.message
          this.alert(`Error Retrieving Batches: ${message}`)
        } finally {
          this.loading = false
        }
      }
    },
    async getBatches() {
      if (this.adGroup.id){

        if (!this.shouldPoll) this.loading = true

        this.startDt = null 
        this.endDt = null
        if (this.batchDate) {
          this.startDt = this.batchDate
        }
        if (this.batchEndDate){
          this.endDt = this.batchEndDate
        }
        
        try {
          const { data } = await Batches.getBatches(
            this.adGroup.id,
            this.startDt,
            this.endDt,
            this.pageSize,
            this.from,
            this.searchValues
          )
          this.batches = data
        } catch (err) {
          console.error(err)
          const message = err?.response?.data?.message || err.message
          this.alert(`Error Retrieving Batches: ${message}`)
        } finally {
          this.loading = false
        }
      }
    },
    async batchCreate() {
      const valid = this.$refs.form.validate()
      if (valid) {
        this.createMenu = false
        this.creating = true
        try {
          await Batches.batchCreate(
            this.adGroup.id,
            this.batchDate,
            this.batchName,
            this.batchEndDate,
            this.batchType
          )
          this.notify(`${this.batchName} Created Successfully`)
          this.$refs.form.reset()
        } catch (err) {
          console.error(err)
          const message = err?.response?.data?.message || err.message
          this.alert(`Error Creating ${this.batchName}: ${message}`)
        } finally {
          await this.getBatches()
          this.creating = false
        }
      }
    },
    closeTprSrpUploadMenu() {
      this.tprUploadMenu = false
    },
    viewReport(batch) {

      let params = {
        tpr_date: batch.tpr_date,
        tpr_end_date: batch.tpr_end_date,
        selected_batch: batch
      }

      if (this.isTprProRetailUser) {
        params.store = this.store
      } else {
        params.ad_group = this.ad_group
      }

      if (this.isBatchAvailable.includes(batch.status)) {
        // Adjust query based on isTprProRetailUser
        const query = this.isTprProRetailUser
          ? { id: batch.tpr_batch_id }
          : { id: batch.id }
        this.$router.push({
          name: 'BatchView',
          params: params,
          query: query
        })
      } else {
        return
      }
    },

    clearBatchDate() {
      this.batchDate = ''
      this.menu = true
      if(!this.isTprProRetailUser){
        this.getBatches()
      } else {
        this.getStoreBatches()
      }
    },
    clearEndDate() {
      this.batchEndDate = ''
      this.endMenu = true
      if(!this.isTprProRetailUser){
        this.getBatches()
      } else {
        this.getStoreBatches()
      }
      return
    }
  }
}
</script>
<style>
.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: #fff !important
}
</style>