<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-toolbar flat color="toolbar">
      <v-col md="3" lg="3" xl="2" class="pl-0 pr-2">
        <v-autocomplete v-if="!isTprProRetailUser"
          v-model="selectedAdGroup"
          :items="userAdGroups"
          item-text="name"
          item-value="id"
          outlined
          return-object
          dense
          class="mt-7 ma-0"
          background-color="input"
          label="Ad Group"
          @change="onSelectionChange"
        ></v-autocomplete>
        <v-autocomplete v-else
          v-model="store"
          :items="userStores"
          background-color="input"
          class="mt-7 ma-0"
          dense
          item-text="store_name"
          item-id="store_party_id"
          label="Store"
          :menu-props="{ maxHeight: 210 }"
          outlined
          return-object
          @change="onSelectionChange">
        </v-autocomplete>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="pr-6" align="end">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              class="blue white--text  ml-2"
              height="38"
              v-bind="attrs"
              v-on="on"
              @click="openRulesModal">
              create
            </v-btn>
          </template>
          <span>Create Rules</span>
        </v-tooltip>
      </v-col>
    </v-toolbar>
    <v-data-table v-if="groupedRules.length > 0 || isLoading"
      :headers="computedHeaders"
      item-key="name"
      class="elevation-1"
      hide-default-footer
      :loading="isLoading"
      dense>
      <template v-slot:body>
        <tbody v-for="(ruleGroup, index) in groupedRules" :key="index">
          <tr>
            <td colspan="5" class="ruleNames font-weight-bold">{{index + 1}}. {{ ruleGroup.rule_display_name }}</td>
          </tr>
          <tr v-for="(rule, idx) in ruleGroup.rules" :key="idx">
            <td>{{ rule.rule_display_name }}</td>
            <td v-if="rule.label === 'None'">
              <v-icon color="green">mdi-arrow-up-circle</v-icon>
            </td>
            <td v-else-if="rule.label === 'Number'">
              ${{ rule.rule_value }}
            </td>
            <td v-else>{{ rule.rule_value }}%</td>
            <td v-if="!rule.filter_lvl">
              <v-icon color="red">mdi-alpha-x-circle</v-icon>
            </td>
            <td v-else>{{ getRuleDisplay(rule.filter_lvl) }}</td>
            <td v-if="!rule.filter_value">
              <v-icon color="red">mdi-alpha-x-circle</v-icon>
            </td>
            <td v-else>{{ rule.filter_value }}</td>
            <td>
              <v-menu offset-y>
                <template #activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon>mdi-pencil-box-outline</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="editRule(rule)">
                    <v-list-item-icon>
                      <v-icon>mdi-pencil</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="deleteRule(rule)">
                    <v-list-item-icon>
                      <v-icon color="red">mdi-trash-can</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-data-table v-else
      :headers="computedHeaders"
      :loading="false"
      no-data-text="No data available"
      item-key="name"
      class="elevation-1"
      hide-default-footer
      dense>
    </v-data-table>
    <v-dialog :max-width="isDefinitionsVisible ? '1000px' : '500px'" v-model="isRulesModalOpen">
      <v-form @submit.prevent v-model="valid">
        <v-card>
          <v-card-title class="main white--text pa-2 d-flex justify-space-between">
            <span> Rule Manager </span>
            <v-icon
              class="ml-auto"
              color="grey lighten-1"
              @click="toggleDefinitions"
            >mdi-information-outline</v-icon>
          </v-card-title>

          <v-card-text class="pa-3">
            <v-row>
              <v-col :cols="isDefinitionsVisible ? 6 : 12" class="pr-3" style="border-right: 1px solid #e0e0e0;">
                <v-stepper v-model="step" vertical linear class="mb-5">
                  
                  <!-- Step 1: Rule Selection -->
                  <v-stepper-step :complete="isRuleSelected" :editable="!!editingRuleId" step="1">
                    Select Rule <span v-if="selectedRule.rule_display_name" class="displayedValues"> {{ selectedRule.rule_display_name }}</span>
                  </v-stepper-step>
                  <v-stepper-content step="1">
                    <v-row dense>
                      <v-col cols="8">
                        <v-select
                          class="pt-1"
                          v-model="selectedRule.rule_display_name"
                          :items="ruleOptions"
                          label="Select Rule"
                          item-text="display_name"
                          item-value="display_name"
                          outlined
                          :rules="ruleSelect"
                          dense
                          @change="setInitialValue"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-stepper-content>

                  <!-- Step 2: Rule Value Input -->
                  <v-stepper-step :complete="isValueEntered" :editable="isRuleSelected || !!editingRuleId" step="2">
                    Enter Value <span v-if="selectedRule.rule_value" class="displayedValues"> {{ selectedRule.rule_value }}</span>
                  </v-stepper-step>
                  <v-stepper-content step="2">
                    <v-row dense>
                      <v-col cols="6">
                        <v-text-field 
                          class="pt-1"
                          v-model="selectedRule.rule_value"
                          label="Value"
                          outlined
                          placeholder="Enter value"
                          :rules="getRuleValueRules()"
                          dense
                          type="number"
                          min="0"
                          step="1"
                          v-if="selectedRule.label === 'Number' || selectedRule.label === 'Percentage'"
                          @change="handleValueInput"
                        >
                          <template v-slot:prepend-inner>
                            <span v-if="selectedRule.label === 'Number'">$</span>
                            <span v-if="selectedRule.label === 'Percentage'">%</span>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-stepper-content>

                  <!-- Step 3: Filter Selection -->
                  <v-stepper-step :complete="isFilterComplete" :editable="isValueEntered || !!editingRuleId" step="3">
                    Filters (Optional)
                  <span v-if="displayFilterText" class="displayedValues"> {{ displayFilterText }}</span>
                  </v-stepper-step>
                  <v-stepper-content step="3">
                    <v-row>
                      <v-col cols="8">
                        <v-select
                          class="pt-1"
                          v-model="selectedSubFilter"
                          :items="combinedFilterOptions"
                          label="Select Filter"
                          outlined
                          clearable
                          dense
                          @change="onSelectedSubfilterChange"
                        >
                          <template v-slot:item="{ item }">
                            <span>{{ item.label }} - {{ item.name }}</span>
                          </template>

                          <template v-slot:selection="{ item }">
                            <span v-if="item">{{ item.label }} - {{ item.name }}</span>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                    <!-- Skip Step 3 if no selection is made -->
                    <v-btn @click="skipStep3" color="primary" small class="mt-3">Skip</v-btn>
                  </v-stepper-content>

                  <!-- step 4 -->
                  <v-stepper-step :complete="isRulesComplete" :editable="isFilterComplete" step="4">
                    Complete
                  </v-stepper-step>
                  <v-stepper-content step="4">
                    <v-row dense>
                      <v-col cols="2" class="pt-1">
                        <v-btn @click="completeRuleset" color="primary" class="mb-4" small>Complete</v-btn>
                      </v-col>
                    </v-row>
                  </v-stepper-content>
                </v-stepper> 
              </v-col>

              <v-col v-if="isDefinitionsVisible" cols="6" class="pl-3">
                <h3 class="definitions-title" style="border-bottom: 1px solid #e0e0e0;">Definitions</h3>
                  <div
                    v-for="(definition, index) in definitions"
                    :key="index"
                    class="definition-term"
                  >
                  {{ index + 1 }}. <strong>{{ definition.term }}</strong>
                  <ul class="definition-list">
                    <li v-for="condition in formatDescription(definition.description)" :key="condition">{{ condition }}</li>
                  </ul>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>
<script>
import { fullwidth } from "@/mixins/fullwidth"
import { paging } from "@/mixins/paging"
import { displayHelpers } from "@/mixins/displayHelpers"
import { userAccess } from "@/mixins/user-access"
import { utils } from "@/mixins/utils"
import { shared } from "@/mixins/shared"
import Rules from "@/axios/rules-management"

export default {
  name: "TprPro",
  mixins: [
    fullwidth,
    displayHelpers,
    paging,
    userAccess,
    utils,
    shared
  ],
  data() {
    return {
      categoriesOptions: [],
      selectedAdGroup: {},
      store: {},
      userStores: [],
      step: 1,
      level: '',
      filter_lvl: '',
      filter_value: '',
      filterStepperOpen: false,
      selectedFilterLvl: null,
      selectedSubFilter: null,
      departmentOptions: [],
      selectedRule: [],
      ruleOptions: [],
      ruleSelect: [
        value => {
          if (value) return true
          return 'Rule selection is required'
        }
      ],
      rulesData: [],
      relationships: [],
      isRulesModalOpen: false,
      isRuleSelected: false,
      isValueEntered: false, 
      valid: false,
      editingRuleId: null,
      isFilterComplete: false,
      isRulesComplete: false,
      definitions: [
        { term: "Rounding down if < 5 up if > 5", description: "If last digit is 0, subtract .01. If last digit > 5, move up to 9. If last digit < 5 move down to 9" },
        { term: "Rounding up to 5 or 9", description: "If last digit < 5 set to 5. If last digit 6 to 8 set to 9" },
        { term: "Rounding up to 7 or 9", description: "If last digit < 7 set to 7. If last digit is 8 set to 9" },
        { term: "Rounding up to 5 or 9 minus 1 on 0", description: "If last digit is 0, subtract .01. If 1 through 5, set to 5. If 6 through 8, set to 9" },
        { term: "Rounding up to 9", description: "If last digit is 0 through 8, set up to 9." },
        { term: "Rounding up to nearest odd number minus 1 on 0", description: "If last digit is 0, subtract .01. If 1 through 2, move up to 3. If 4, set to 5. If 6, set to 7. If 8, set to 9" },

      ],
      isDefinitionsVisible: false,
      isLoading: false
    }
  },
  mounted() {
    this.initializeData()
  },
  watch: {
    userAdGroups: {
      handler(newValue) {
        if (newValue?.length > 0 && !sessionStorage.tpr_pro_ad_group) {
          let index = 0
          this.selectedAdGroup = newValue[index]
        }
      },
      deep: true
    },
    store(newValue) {
      if (newValue){
        this.saveState()
        // this.getRulesets()
      }
    },
    selectedAdGroup(newValue){
      if(newValue) {
        this.saveState()
        // this.getRulesets()
      }
    },
    selectedSubFilter(newValue) {
      if (!newValue) {
        this.filter_lvl = ''
        this.filter_value = ''
      }
    }
  },
  computed: {
    isTprProRetailUser() {
      return this.userGroups.includes('tpr-manager.pro')
    },
    groupedRules() {
      const grouped = this.rulesData.reduce((acc, rule) => {
        const groupName = rule.rule_display_name
        if (!acc[groupName]) {
          acc[groupName] = { rule_display_name: groupName, rules: [] }
        }
        acc[groupName].rules.push(rule)
        return acc
      }, {})

      return Object.values(grouped).sort((a, b) => {
        const firstPriorityA = a.rules.length ? Number(a.rules[0].rule_priority) : Infinity
        const firstPriorityB = b.rules.length ? Number(b.rules[0].rule_priority) : Infinity
        return firstPriorityA - firstPriorityB
      })
    },
    computedHeaders() {
      return [
        { text: "Rule", class: 'accent white--text' },
        { text: "Rule Value", class: 'accent white--text' },
        { text: "Rule Filter", class: 'accent white--text' },
        { text: "Rule Filter Value", class: 'accent white--text' },
        { text: "Actions", value: "actions", class: 'accent white--text' }
      ]
    },
    combinedFilterOptions() {
      return [
        ...this.departmentOptions.map(department => ({
          label: 'Department',
          name: department
        })),
        ...this.categoriesOptions.map(category => ({
          label: 'Category',
          name: category 
        }))
      ]
    },
    displayFilterText() {
      return this.filter_lvl && this.filter_value
        ? `${this.filter_lvl} - ${this.filter_value}`
        : ''
    }
  },
  methods: {
    formatDescription(description) {
      return description.split(/(?=If\s)/g).map(item => item.trim())
    },
    toggleDefinitions() {
      this.isDefinitionsVisible = !this.isDefinitionsVisible
    }, 
    getRuleValueRules() {
      const rule = this.selectedRule
      let rules = []

      if (rule.label === 'None' || rule.label === 'rounding') {
        return []
      }

      rules.push(value => {
        if (!value || isNaN(value)) {
          return 'Value must be a valid number'
        }
        return true
      })

      rules.push(value => {
        if (typeof value === 'string' && value.includes('.')) {
          const decimalPlaces = value.split('.')[1].length
          if (decimalPlaces > 2) {
            return 'Only up to 2 decimal places allowed'
          }
        }
        return true
      })

      if (rule.label === 'Percentage' && rule.rule_name === 'pass_thru_pct') {
        rules.push(value => {
          const numericValue = Number(value)
          if (numericValue < 60 || numericValue > 100) {
            return 'Value must be between 60%-100%'
          }
          return true
        })
      } else if (rule.label === 'Percentage' && rule.rule_name !== 'pass_thru_pct') {
        rules.push(value => {
          const numericValue = Number(value)
          if (numericValue < 0 || numericValue > 100) {
            return 'Value must be between 0-100%'
          }
          return true
        })
      } else if (rule.label === 'Number') {
        rules.push(value => {
          const numericValue = Number(value)
          if (numericValue < 0) {
            return 'Value must be greater than 0'
          }
          return true
        })
      }

      return rules
    },
    saveState() {
      if (!this.isTprProRetailUser) {
        if (this.selectedAdGroup) {
          sessionStorage.setItem("tpr_pro_ad_group", JSON.stringify(this.selectedAdGroup))
        }
      } else {
        if (this.store) {
          sessionStorage.setItem("tpr_pro_selected_store", JSON.stringify(this.store))
        }
      }
    },

    onSelectedSubfilterChange() {
      if (this.selectedSubFilter) {
        console.log(this.selectedSubFilter)
        const selectedOption = this.combinedFilterOptions.find(
          option => option.name === this.selectedSubFilter.name && option.label === this.selectedSubFilter.label
        )
        if (selectedOption) {
          this.filter_lvl = selectedOption.label.toLowerCase()
          this.filter_value = selectedOption.name
          this.isFilterComplete = true
          this.step = 4
        }
      } else {
        this.isFilterComplete = true
        this.step = 4
      }
    },

    skipStep3() {
      this.isFilterComplete = true
      this.step = 4
    },
    getRuleDisplay(key) {
      const ruleMapping = {
        'ad_group' : 'Ad Group',
        'store' : 'Store',
        'department' : 'Department',
        'category' : 'Category'
      }
      return ruleMapping[key] || key
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString()
    },
    openRulesModal() {
      this.isRulesModalOpen = true
      this.editingRuleId = null
      this.selectedRule = [{ rule_display_name: "", rule_value: "", rule_name: "", rule_priority: "" }]
      this.step = 1
      this.isFilterComplete = false
      this.isRulesComplete = false
      this.selectedFilterLvl = null
      this.selectedSubFilter = null
    },
    closeRulesModal() {
      this.isRulesModalOpen = false
      this.selectedRule = []
      this.description = ''
      this.step = 1
      this.isFilterComplete = false
      this.isRulesComplete = false
      this.selectedFilterLvl = null
      this.selectedSubFilter = null
    },

    setInitialValue() {
      const ruleDisplayName = this.selectedRule.rule_display_name
      const ruleOption = this.ruleOptions.find(option => option.display_name === ruleDisplayName)

      if (ruleOption) {
        this.selectedRule.rule_name = ruleOption.name
        this.selectedRule.label = ruleOption.label

        if (ruleOption.label === 'None') {
          this.selectedRule.rule_value = ''
          this.isRuleSelected = true
          this.step = 3
        } else {
          this.selectedRule.rule_value = 0
          this.isRuleSelected = true
          this.step = 2
        }
      }
    },

    handleValueInput() {
      const rules = this.getRuleValueRules()
      const value = this.selectedRule.rule_value
      const isValid = rules.every(rule => rule(value) === true)
      
      if (isValid && value) {
        this.isValueEntered = true
        this.step = 3
      }
    },
    completeRuleset() {
      if (!this.valid) {
        return
      }
      this.createOrUpdateRulesets()
      this.closeRulesModal()
    },
    editRule(rule) {
      this.isRulesModalOpen = true
      this.editingRuleId = rule.ruleset_id
      
      this.isRuleSelected = true
      this.isValueEntered = true
      this.isFilterComplete = true 
      this.isRulesComplete = true
      
      this.selectedRule = {
        rule_display_name: rule.rule_display_name,
        rule_value: rule.label === 'None' ? '' : rule.rule_value,
        rule_priority: rule.rule_priority,
        rule_name: rule.rule_name,
        label: rule.label || 'None'
      }
      this.filter_lvl = rule.filter_lvl || ''
      this.filter_value = rule.filter_value || ''

      this.selectedSubFilter = this.combinedFilterOptions.find(option => 
        option.name === this.filter_value && option.label.toLowerCase() === this.filter_lvl
      )

    },
    async onSelectionChange() {
      this.isLoading = true
      try {
        this.saveState()
        await Promise.all([
          this.getRules(),
          this.getRulesets()
        ])
      } catch (err) {
        console.error('Error during selection change:', err)
      } finally {
        this.isLoading = false
      }
    }, 
    createOrUpdateRulesets() {
      if (!this.valid) {
        return
      }
      const ruleData = {
        filter_lvl: this.filter_lvl || '',
        filter_value: this.filter_value || '',
        level: this.isTprProRetailUser ? 'store' : 'ad_group',
        party_id: this.isTprProRetailUser ? this.store.store_party_id : this.selectedAdGroup.id,
        name: this.isTprProRetailUser ? this.store.store_name : this.selectedAdGroup.name,
        modified_by: this.$auth.user.email,
        rule_list: [{
          display_name: this.selectedRule?.rule_display_name,
          name: this.selectedRule?.rule_name,
          value: Number(this.selectedRule?.rule_value)
        }],
      }

      if (this.editingRuleId) {
        ruleData.ruleset_id = this.editingRuleId
        this.updateRulesets(ruleData)
      } else {
        ruleData.created_by = this.$auth.user.email
        this.createRulesets(ruleData)
      }
    },
    async initializeData() {
      try {
        this.isLoading = true
        if (!this.isTprProRetailUser) {
          const savedAdGroup = sessionStorage.getItem("tpr_pro_ad_group")
          this.selectedAdGroup = savedAdGroup ? JSON.parse(savedAdGroup) : this.userAdGroups[0]
        } else {
          await this.getStores()
          const savedStore = sessionStorage.getItem("tpr_pro_selected_store")
          this.store = savedStore ? JSON.parse(savedStore) : this.userStores[0]
        }

        if (this.selectedAdGroup || this.store) {
          this.getRulesets()
          this.getRules()
          this.getDepartments()
          this.getCategories()
        }
        this.saveState()

      } catch (err) {
        console.error("Error initializing data:", err)
      } finally {
        this.isLoading = false
      }
    },
    async getCategories() {
      try {
        const res = await Rules.getCategories()
        this.categoriesOptions = res.data
      } catch (err) {
        console.log('Get Categories errors: ', err)
      }
    },
    async getDepartments() {
      try {
        const res = await Rules.getDepartments()
        this.departmentOptions = res.data
      } catch (err) {
        console.log('Get Department errors: ', err)
      }
    },
    async getRules() {
      try {
        this.isLoading = true
        const res = await Rules.getRules()
        this.ruleOptions = res.data.sort((a, b) => a.priority - b.priority)
      } catch (err) {
        console.error("Error fetching rules:", err)
      } finally {
        this.isLoading = false
      }
    },
    async getStores(){
      try{
        const res = await Rules.getStores(this.$auth.user.email, this.$auth.tenant)
        this.userStores = res.data
      } catch (err) {
        console.log('Get stores Error: ', err)
      }
    },
    async getRulesets() {
      this.isLoading = true
      try {
        const res = await Rules.getRulesets()
        let matchingRulesets
        if(!this.isTprProRetailUser){
          matchingRulesets = res.data.filter(
            ({ party_id }) => party_id === this.selectedAdGroup.id
          )
        } else {
          matchingRulesets = res.data.filter(
            ({ party_id }) => party_id === this.store.store_party_id
          )
        }
        this.rulesData = matchingRulesets.map((ruleset) => ({
          ...ruleset,
          created_date: this.formatDate(ruleset.created_date),
          modified_date: this.formatDate(ruleset.modified_date),
        }))
      } catch (err) {
        console.error("Error fetching rulesets:", err)
      } finally {
        this.isLoading = false
      }
    },
    async createRulesets(ruleData) {
      try {
        this.isLoading = true
        await Rules.createRulesets(ruleData)
        this.closeRulesModal()
        await this.getRulesets()
      } catch (err) {
        if (err.response && err.response.status === 406) {
          const errorMessage = err.response.data?.message || 'An error occurred'
          const minValue = err.response.data?.extras?.value?.min_value
          const snackbarText = minValue === 100 ? errorMessage : `${errorMessage} Minimum value allowed: ${minValue}`

          this.$store.dispatch('setSnackbar', {
            status: 'error',
            text: snackbarText
          })
        } else {
          console.error('Error creating ruleset:', err)
        }
      } finally {
        this.isLoading = false
      }
    },
    async updateRulesets(ruleData) {
      try {
        this.isLoading = true
        await Rules.updateRulesets(ruleData)
        this.closeRulesModal()
        await this.getRulesets()
      } catch (err) {
        console.error('Error updating ruleset:', err)
        if (err.response && err.response.status === 406) {
          const errorMessage = err.response.data?.message || 'An error occurred'
          const minValue = err.response.data?.extras?.value?.min_value
          const snackbarText = minValue === 100 ? errorMessage : `${errorMessage} Minimum value allowed: ${minValue}`

          this.$store.dispatch('setSnackbar', {
            status: 'error',
            text: snackbarText
          })
        } else {
          console.error('Error creating ruleset:', err)
        }
      } finally {
        this.isLoading = false
      }
    },
    async deleteRule(rule) {
      try {
        await Rules.deleteRulesets(rule.ruleset_id)
        await this.getRulesets()
      } catch (err) {
        console.error("Error deleting rule:", err)
      }
    } 
  }
}
</script>
<style scoped>
.displayedValues {
  font-weight: bolder;
  font-style: italic;
  padding-left: 10px;
}
.definitions-title {
  text-align: center;
}

.definition-list {
  display: none;
  margin-left: 20px;
}
.definition-term {
  padding: 5px;
}

.definition-term:hover .definition-list {
  display: block;
}
.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: #fff !important
}
.v-card-text {
  padding: 8px 16px
}
.v-card-actions {
  padding: 8px 16px
}
.ruleNames{
  background-color: rgb(225, 222, 222)
}
</style>