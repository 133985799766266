var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-toolbar",
        { attrs: { flat: "", color: "toolbar" } },
        [
          _c(
            "v-col",
            { staticClass: "pl-0 pr-2", attrs: { md: "3", lg: "3", xl: "2" } },
            [
              !_vm.isTprProRetailUser
                ? _c("v-autocomplete", {
                    staticClass: "mt-7 ma-0",
                    attrs: {
                      items: _vm.userAdGroups,
                      "item-text": "name",
                      "item-value": "id",
                      outlined: "",
                      "return-object": "",
                      dense: "",
                      "background-color": "input",
                      label: "Ad Group",
                    },
                    on: { change: _vm.onSelectionChange },
                    model: {
                      value: _vm.selectedAdGroup,
                      callback: function ($$v) {
                        _vm.selectedAdGroup = $$v
                      },
                      expression: "selectedAdGroup",
                    },
                  })
                : _c("v-autocomplete", {
                    staticClass: "mt-7 ma-0",
                    attrs: {
                      items: _vm.userStores,
                      "background-color": "input",
                      dense: "",
                      "item-text": "store_name",
                      "item-id": "store_party_id",
                      label: "Store",
                      "menu-props": { maxHeight: 210 },
                      outlined: "",
                      "return-object": "",
                    },
                    on: { change: _vm.onSelectionChange },
                    model: {
                      value: _vm.store,
                      callback: function ($$v) {
                        _vm.store = $$v
                      },
                      expression: "store",
                    },
                  }),
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-col",
            { staticClass: "pr-6", attrs: { align: "end" } },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "blue white--text ml-2",
                                  attrs: { small: "", height: "38" },
                                  on: { click: _vm.openRulesModal },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_vm._v(" create ")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Create Rules")])]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.groupedRules.length > 0 || _vm.isLoading
        ? _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              headers: _vm.computedHeaders,
              "item-key": "name",
              "hide-default-footer": "",
              loading: _vm.isLoading,
              dense: "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "body",
                  fn: function () {
                    return _vm._l(
                      _vm.groupedRules,
                      function (ruleGroup, index) {
                        return _c(
                          "tbody",
                          { key: index },
                          [
                            _c("tr", [
                              _c(
                                "td",
                                {
                                  staticClass: "ruleNames font-weight-bold",
                                  attrs: { colspan: "5" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(index + 1) +
                                      ". " +
                                      _vm._s(ruleGroup.rule_display_name)
                                  ),
                                ]
                              ),
                            ]),
                            _vm._l(ruleGroup.rules, function (rule, idx) {
                              return _c("tr", { key: idx }, [
                                _c("td", [
                                  _vm._v(_vm._s(rule.rule_display_name)),
                                ]),
                                rule.label === "None"
                                  ? _c(
                                      "td",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "green" } },
                                          [_vm._v("mdi-arrow-up-circle")]
                                        ),
                                      ],
                                      1
                                    )
                                  : rule.label === "Number"
                                  ? _c("td", [
                                      _vm._v(
                                        " $" + _vm._s(rule.rule_value) + " "
                                      ),
                                    ])
                                  : _c("td", [
                                      _vm._v(_vm._s(rule.rule_value) + "%"),
                                    ]),
                                !rule.filter_lvl
                                  ? _c(
                                      "td",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "red" } },
                                          [_vm._v("mdi-alpha-x-circle")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getRuleDisplay(rule.filter_lvl)
                                        )
                                      ),
                                    ]),
                                !rule.filter_value
                                  ? _c(
                                      "td",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "red" } },
                                          [_vm._v("mdi-alpha-x-circle")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c("td", [
                                      _vm._v(_vm._s(rule.filter_value)),
                                    ]),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "v-menu",
                                      {
                                        attrs: { "offset-y": "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      { attrs: { icon: "" } },
                                                      on
                                                    ),
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-pencil-box-outline"
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c(
                                          "v-list",
                                          [
                                            _c(
                                              "v-list-item",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editRule(rule)
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-list-item-icon",
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("mdi-pencil"),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _c("v-list-item-title", [
                                                  _vm._v("Edit"),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteRule(rule)
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-list-item-icon",
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: { color: "red" },
                                                      },
                                                      [_vm._v("mdi-trash-can")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c("v-list-item-title", [
                                                  _vm._v("Delete"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            }),
                          ],
                          2
                        )
                      }
                    )
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3253742132
            ),
          })
        : _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              headers: _vm.computedHeaders,
              loading: false,
              "no-data-text": "No data available",
              "item-key": "name",
              "hide-default-footer": "",
              dense: "",
            },
          }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": _vm.isDefinitionsVisible ? "1000px" : "500px" },
          model: {
            value: _vm.isRulesModalOpen,
            callback: function ($$v) {
              _vm.isRulesModalOpen = $$v
            },
            expression: "isRulesModalOpen",
          },
        },
        [
          _c(
            "v-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass:
                        "main white--text pa-2 d-flex justify-space-between",
                    },
                    [
                      _c("span", [_vm._v(" Rule Manager ")]),
                      _c(
                        "v-icon",
                        {
                          staticClass: "ml-auto",
                          attrs: { color: "grey lighten-1" },
                          on: { click: _vm.toggleDefinitions },
                        },
                        [_vm._v("mdi-information-outline")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "pa-3" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pr-3",
                              staticStyle: {
                                "border-right": "1px solid #e0e0e0",
                              },
                              attrs: {
                                cols: _vm.isDefinitionsVisible ? 6 : 12,
                              },
                            },
                            [
                              _c(
                                "v-stepper",
                                {
                                  staticClass: "mb-5",
                                  attrs: { vertical: "", linear: "" },
                                  model: {
                                    value: _vm.step,
                                    callback: function ($$v) {
                                      _vm.step = $$v
                                    },
                                    expression: "step",
                                  },
                                },
                                [
                                  _c(
                                    "v-stepper-step",
                                    {
                                      attrs: {
                                        complete: _vm.isRuleSelected,
                                        editable: !!_vm.editingRuleId,
                                        step: "1",
                                      },
                                    },
                                    [
                                      _vm._v(" Select Rule "),
                                      _vm.selectedRule.rule_display_name
                                        ? _c(
                                            "span",
                                            { staticClass: "displayedValues" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.selectedRule
                                                      .rule_display_name
                                                  )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "v-stepper-content",
                                    { attrs: { step: "1" } },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "8" } },
                                            [
                                              _c("v-select", {
                                                staticClass: "pt-1",
                                                attrs: {
                                                  items: _vm.ruleOptions,
                                                  label: "Select Rule",
                                                  "item-text": "display_name",
                                                  "item-value": "display_name",
                                                  outlined: "",
                                                  rules: _vm.ruleSelect,
                                                  dense: "",
                                                },
                                                on: {
                                                  change: _vm.setInitialValue,
                                                },
                                                model: {
                                                  value:
                                                    _vm.selectedRule
                                                      .rule_display_name,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.selectedRule,
                                                      "rule_display_name",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "selectedRule.rule_display_name",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-stepper-step",
                                    {
                                      attrs: {
                                        complete: _vm.isValueEntered,
                                        editable:
                                          _vm.isRuleSelected ||
                                          !!_vm.editingRuleId,
                                        step: "2",
                                      },
                                    },
                                    [
                                      _vm._v(" Enter Value "),
                                      _vm.selectedRule.rule_value
                                        ? _c(
                                            "span",
                                            { staticClass: "displayedValues" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.selectedRule.rule_value
                                                  )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "v-stepper-content",
                                    { attrs: { step: "2" } },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _vm.selectedRule.label ===
                                                "Number" ||
                                              _vm.selectedRule.label ===
                                                "Percentage"
                                                ? _c("v-text-field", {
                                                    staticClass: "pt-1",
                                                    attrs: {
                                                      label: "Value",
                                                      outlined: "",
                                                      placeholder:
                                                        "Enter value",
                                                      rules:
                                                        _vm.getRuleValueRules(),
                                                      dense: "",
                                                      type: "number",
                                                      min: "0",
                                                      step: "1",
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.handleValueInput,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "prepend-inner",
                                                          fn: function () {
                                                            return [
                                                              _vm.selectedRule
                                                                .label ===
                                                              "Number"
                                                                ? _c("span", [
                                                                    _vm._v("$"),
                                                                  ])
                                                                : _vm._e(),
                                                              _vm.selectedRule
                                                                .label ===
                                                              "Percentage"
                                                                ? _c("span", [
                                                                    _vm._v("%"),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      240040392
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.selectedRule
                                                          .rule_value,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.selectedRule,
                                                          "rule_value",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedRule.rule_value",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-stepper-step",
                                    {
                                      attrs: {
                                        complete: _vm.isFilterComplete,
                                        editable:
                                          _vm.isValueEntered ||
                                          !!_vm.editingRuleId,
                                        step: "3",
                                      },
                                    },
                                    [
                                      _vm._v(" Filters (Optional) "),
                                      _vm.displayFilterText
                                        ? _c(
                                            "span",
                                            { staticClass: "displayedValues" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.displayFilterText)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "v-stepper-content",
                                    { attrs: { step: "3" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "8" } },
                                            [
                                              _c("v-select", {
                                                staticClass: "pt-1",
                                                attrs: {
                                                  items:
                                                    _vm.combinedFilterOptions,
                                                  label: "Select Filter",
                                                  outlined: "",
                                                  clearable: "",
                                                  dense: "",
                                                },
                                                on: {
                                                  change:
                                                    _vm.onSelectedSubfilterChange,
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "item",
                                                    fn: function ({ item }) {
                                                      return [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(item.label) +
                                                              " - " +
                                                              _vm._s(item.name)
                                                          ),
                                                        ]),
                                                      ]
                                                    },
                                                  },
                                                  {
                                                    key: "selection",
                                                    fn: function ({ item }) {
                                                      return [
                                                        item
                                                          ? _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.label
                                                                ) +
                                                                  " - " +
                                                                  _vm._s(
                                                                    item.name
                                                                  )
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                ]),
                                                model: {
                                                  value: _vm.selectedSubFilter,
                                                  callback: function ($$v) {
                                                    _vm.selectedSubFilter = $$v
                                                  },
                                                  expression:
                                                    "selectedSubFilter",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mt-3",
                                          attrs: {
                                            color: "primary",
                                            small: "",
                                          },
                                          on: { click: _vm.skipStep3 },
                                        },
                                        [_vm._v("Skip")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-stepper-step",
                                    {
                                      attrs: {
                                        complete: _vm.isRulesComplete,
                                        editable: _vm.isFilterComplete,
                                        step: "4",
                                      },
                                    },
                                    [_vm._v(" Complete ")]
                                  ),
                                  _c(
                                    "v-stepper-content",
                                    { attrs: { step: "4" } },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-1",
                                              attrs: { cols: "2" },
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mb-4",
                                                  attrs: {
                                                    color: "primary",
                                                    small: "",
                                                  },
                                                  on: {
                                                    click: _vm.completeRuleset,
                                                  },
                                                },
                                                [_vm._v("Complete")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.isDefinitionsVisible
                            ? _c(
                                "v-col",
                                { staticClass: "pl-3", attrs: { cols: "6" } },
                                [
                                  _c(
                                    "h3",
                                    {
                                      staticClass: "definitions-title",
                                      staticStyle: {
                                        "border-bottom": "1px solid #e0e0e0",
                                      },
                                    },
                                    [_vm._v("Definitions")]
                                  ),
                                  _vm._l(
                                    _vm.definitions,
                                    function (definition, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "definition-term",
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(index + 1) + ". "
                                          ),
                                          _c("strong", [
                                            _vm._v(_vm._s(definition.term)),
                                          ]),
                                          _c(
                                            "ul",
                                            { staticClass: "definition-list" },
                                            _vm._l(
                                              _vm.formatDescription(
                                                definition.description
                                              ),
                                              function (condition) {
                                                return _c(
                                                  "li",
                                                  { key: condition },
                                                  [_vm._v(_vm._s(condition))]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }